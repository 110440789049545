import { TippyProps } from '@tippyjs/react';
import React from 'react';
import Tooltip from 'shared/tooltip/Tooltip';

export type TooltipPlacement = TippyProps['placement'];
export type ButtonContainerProps = {
  children: React.ReactNode;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  tooltipText?: string;
  className?: string;
  tooltipPlacement?: TooltipPlacement;
};

export default function ButtonContainer(props: ButtonContainerProps) {
  if (props.tooltipText) {
    return (
      <Tooltip
        tippyProps={{
          content: props.tooltipText,
          placement: props.tooltipPlacement ?? 'left'
        }}
      >
        <div className={props.className} onMouseEnter={props.onMouseEnter}>
          {props.children}
        </div>
      </Tooltip>
    );
  }

  return <>{props.children}</>;
}
