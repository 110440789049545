import classNames from 'classnames';
import * as React from 'react';
import ButtonContainer from './ButtonContainer';
import { ButtonColor, ButtonType } from './types/ButtonModels';
import { getClassesForButtonColor, getClassesForButtonType } from './utils/buttonHelper';

type SvgIconButtonProps = {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  buttonColor: ButtonColor;
  width: number;
  height: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type?: ButtonType;
  buttonClasses?: string;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  id?: string;
  tooltipText?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const SvgIconButton = (props: SvgIconButtonProps) => {
  const { Icon, buttonColor, width, height, onClick, type, buttonClasses } = props;
  const classes = classNames(
    getClassesForButtonType(ButtonType.Icon),
    getClassesForButtonColor(buttonColor),
    buttonClasses
  );

  return (
    <ButtonContainer tooltipText={props.tooltipText}>
      <button
        className={classes}
        onClick={onClick}
        type={type}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        id={props.id}
      >
        <Icon width={width} height={height} className="mx-auto" />
      </button>
    </ButtonContainer>
  );
};

export default SvgIconButton;
