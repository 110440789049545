import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LngLat, Map, PaddingOptions } from '@visual-elements/maplibre-gl';
import { startAppListening } from '../../listenerMiddleware';
import { RootState } from '../../store';
import { storeLocationMapRef } from './instanceReducer';
import { revertLocationMapState } from '../../actions/locationMap';
import { setSearchResult } from './searchReducer';

type ErrorTypes = 'MOVE' | 'OUTSIDE_VIEW' | 'NONE';
export type ViewState = {
  viewIsLocked: boolean;
  viewBoxAspectRatio: number;
  viewBoxResolution: { height: number; width: number } | undefined;
  error: ErrorTypes;
};

const initialState: ViewState = {
  viewIsLocked: false,
  viewBoxAspectRatio: 1,
  viewBoxResolution: undefined,
  error: 'NONE'
};

const viewStateSlice = createSlice({
  name: 'viewState',
  initialState,
  extraReducers: (builder) => builder.addCase(revertLocationMapState, () => initialState),
  reducers: {
    setViewIsLocked(state, action: PayloadAction<boolean>) {
      state.viewIsLocked = action.payload;
    },
    setViewBoxAspectRatio(state, action: PayloadAction<number>) {
      state.viewBoxAspectRatio = action.payload;
    },
    setViewBoxResolution(state, action: PayloadAction<{ height: number; width: number }>) {
      state.viewBoxResolution = action.payload;
    },
    setMapViewError(state, action: PayloadAction<ErrorTypes>) {
      state.error = action.payload;
    }
  }
});

export const selectViewIsLocked = (state: RootState) => state.viewState.viewIsLocked;
export const selectNavigationLockError = (state: RootState) => state.viewState.error;

export const { setViewIsLocked, setMapViewError } = viewStateSlice.actions;

let clearErrorHandlers: () => void = () => null;

startAppListening({
  actionCreator: storeLocationMapRef,
  effect: (action, listenerApi) => {
    const rootState = listenerApi.getState();
    if (rootState.projectConfig.provider !== 'locationMap') throw new Error('Provider must be location map');
    if (!rootState.locationMapInstance.editorMapRef) throw new Error('Location map instance must be defined');
    rootState.locationMapInstance.editorMapRef.on('mapEngineDefined', (data) => {
      enabledOrDisableNavHandlers(data, rootState.viewState.viewIsLocked);
      if (rootState.viewState.viewIsLocked) {
        clearErrorHandlers();
        clearErrorHandlers = addErrorHandlers(data, (error: ErrorTypes) =>
          listenerApi.dispatch(setMapViewError(error))
        );
      }
    });
  }
});

startAppListening({
  actionCreator: setViewIsLocked,
  effect: (action, listenerApi) => {
    const rootState = listenerApi.getState();
    if (rootState.projectConfig.provider !== 'locationMap') throw new Error('Provider must be location map');
    if (!rootState.locationMapInstance.editorMapRef) throw new Error('Location map instance must be defined');
    const map = rootState.locationMapInstance.editorMapRef.mapRef?.getMap();
    if (!map) throw new Error('Map should be defined');
    clearErrorHandlers();
    enabledOrDisableNavHandlers(map, action.payload);

    if (action.payload) {
      clearErrorHandlers = addErrorHandlers(map, (error: ErrorTypes) => listenerApi.dispatch(setMapViewError(error)));
    }
  }
});

startAppListening({
  actionCreator: setSearchResult,
  effect: (action, listenerApi) => {
    const rootState = listenerApi.getState();
    if (rootState.projectConfig.provider !== 'locationMap') throw new Error('Provider must be location map');
    if (!rootState.locationMapInstance.editorMapRef) throw new Error('Location map instance must be defined');

    const aggregatedOptions = rootState.projectConfig.aggregatedOptions;
    const editorMapRef = rootState.locationMapInstance.editorMapRef;
    const map = editorMapRef?.mapRef?.getMap();
    if (!map) return;
    if (!rootState.viewState.viewIsLocked) {
      const heightDifference = map.transform.height - aggregatedOptions.viewState.referenceHeight;
      const widthDifference = map.transform.width - aggregatedOptions.viewState.referenceWidth;

      const defaultPadding = 8;
      const padding: PaddingOptions = {
        top: heightDifference / 2 + defaultPadding,
        bottom: heightDifference / 2 + defaultPadding,
        right: widthDifference / 2 + defaultPadding,
        left: widthDifference / 2 + defaultPadding
      };

      rootState.locationMapInstance.editorMapRef.mapRef
        ?.getMap()
        ?.fitBounds(listenerApi.getState().locationMapSearch.result!.bbox, { padding: padding, maxZoom: 17 });
    } else {
      if (!map.getBounds().contains(new LngLat(action.payload.result.lon, action.payload.result.lat))) {
        listenerApi.dispatch(setMapViewError('OUTSIDE_VIEW'));
      }
    }
  }
});

function addErrorHandlers(map: Map, setError: (error: ErrorTypes) => void) {
  const handleOnMouseDown = (ev: { originalEvent: MouseEvent }) => {
    if (ev.originalEvent.button === 0) {
      setError('MOVE');
    }
  };
  const handleOnWheel = () => {
    setError('MOVE');
  };

  map.on('mousedown', handleOnMouseDown);
  map.on('wheel', handleOnWheel);

  return () => {
    map.off('mousedown', handleOnMouseDown);
    map.off('wheel', handleOnWheel);
  };
}

function enabledOrDisableNavHandlers(map: Map, disable: boolean) {
  if (disable) {
    map.scrollZoom.disable();
    map.boxZoom.disable();
    map.dragPan.disable();
    map.keyboard.disable();
    map.doubleClickZoom.disable();
    map.touchZoomRotate.disable();
    map.dragRotate.disable();
    map.touchPitch.disable();
    map.touchZoomRotate.disable();
    map.getCanvas().style.cursor = 'contextmenu';
  } else {
    map.scrollZoom.enable();
    map.boxZoom.enable();
    map.dragPan.enable();
    map.keyboard.enable();
    map.doubleClickZoom.enable();
    map.touchZoomRotate.enable();
    map.dragRotate.enable();
    map.touchPitch.enable();
    map.touchZoomRotate.enable();
    map.getCanvas().style.cursor = 'grab';
  }
}

export default viewStateSlice.reducer;
