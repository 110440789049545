import { isArray } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from '../../../../redux/actions/action-types';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { updateAggregated } from '../ChartEditor';
import { GenericPayload } from '../ChartEditorPointMap';
import { loadRelevantModules } from 'pages/ChartEditorPage/utils/chartEditorHelper';
import { setAction as setChartAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { getTemplateData } from '../ChartEditorTemplates';
import bbox from '@turf/bbox';
import { FeatureCollection } from '@turf/helpers';
import { LocationMapDataFeatures } from './types';
import { staticMarkerPresets } from './LocationMapMarkerPresetStore';
import { MarkerPreset } from './LocationMapMarker';
import { defaultLocationMapCustomOptions } from '../../meta/DefaultOptions';
import {
  LocationMapCustomizedOptions,
  LocationMapState,
  LocationMapViewStateOptions,
  ProjectConfigLocationMapProps
} from '../../../Editor/reducers/locationMapConfigTypes';
import { PaddingOptions } from '@visual-elements/maplibre-gl';
import { LocationMapInstanceState } from '../../../../redux/reducers/locationMap/instanceReducer';
import { setKeyFrames } from '../../../../redux/reducers/locationMap/animationReducer';
import { setViewIsLocked } from '../../../../redux/reducers/locationMap/viewStateReducer';

type LoadProjectLocationMapPayload = GenericPayload & {
  data: {
    project: any;
  };
};

export function* loadProjectLocationMap(params: LoadProjectLocationMapPayload) {
  const { project: projectData } = params.data;

  yield put(
    setProjectConfigAction({
      provider: 'locationMap'
    })
  );

  const pluginConfig = loadRelevantModules(projectData);

  let templateOptions = [{}];
  let templateMeta = [] as any;

  if (projectData.template) {
    if (isArray(projectData.template)) templateOptions = projectData.template;
    else templateOptions = [projectData.template];
  }

  if (projectData.settings?.template) {
    templateMeta = [projectData.settings?.template[0]];
  }

  let templateDataSettings = {};

  if (projectData.settings?.templateDataSettings) {
    templateDataSettings = projectData.settings.templateDataSettings;
  }

  let customizedOptions: LocationMapCustomizedOptions;
  if (projectData.options) customizedOptions = projectData.options;
  else customizedOptions = defaultLocationMapCustomOptions;

  let themeOptions = {} as any;
  if (typeof projectData.theme !== 'undefined') {
    themeOptions = projectData.theme;
  }

  const dataOptions: LocationMapDataFeatures = projectData.settings.dataProvider;
  const markerPresets = getMarkerPresets();

  const locationMapOptions: LocationMapState = {
    layerOptions: projectData.settings.layerOptions,
    markerMetadata: projectData.settings.markerMetadata ?? {},
    markerPresets: markerPresets,
    viewStateOptions:
      projectData.settings.viewStateOptions ??
      ({
        editorViewState: {
          center: [9, 22],
          bearing: 0,
          pitch: 0,
          zoom: 2
        },
        viewIsLocked: false,
        viewBoxAspectRatio: 1,
        showViewBox: true
      } as LocationMapViewStateOptions)
  };

  yield put(
    setProjectConfigAction({
      customizedOptions,
      templateOptions,
      themeOptions: (themeOptions ?? {}).options ?? {},
      showWizard: false,
      templateDataSettings,
      pluginConfig,
      plugins: projectData?.settings?.plugins ?? {},
      cssModules: projectData?.settings?.plugins?.cssModules ?? [],
      dataOptions,
      templateMeta,
      locationMapOptions: locationMapOptions,
      icons: projectData.icons
    })
  );

  if (customizedOptions.animation) {
    const keyFrames =
      (customizedOptions.animation.initialViewState ? 1 : 0) + (customizedOptions.animation.keyFrames?.length ?? 0);
    yield put(setKeyFrames({ amount: keyFrames }));
  }

  if (projectData.settings.viewIsLocked) {
    yield put(setViewIsLocked(true));
  }

  yield call(updateAggregated, true);

  let chosenWizardTemplate = false;
  if (templateMeta) {
    chosenWizardTemplate = yield call(getTemplateData, {
      data: {
        template: templateMeta
      }
    });
  }

  const projectConfigOptions = { loadingEditor: false };

  yield all([
    put(setProjectConfigAction(projectConfigOptions)),
    put(
      setChartAction({
        chosenWizardTemplate,
        constr: 'Map',
        isMap: true
      })
    )
  ]);
}

// Temporary function until we fetch from api instead
export function getMarkerPresets(): MarkerPreset[] {
  return staticMarkerPresets;
}

/**
 * This function takes all the current features on the map, only markers currently, and fits the map to those features.
 * For this to work we have to take a few steps to achieve it.
 *  1. Fit map to features
 *  2. Get all the dom elements inside the map and calculate how far outside the map they are. Add that as padding.
 *     Also get the difference between the referencepoint and the current height and width, so that the features are within the viewbox
 *  3. Fit the map with the calculated padding and store the viewstate
 *  4. Return to the first position
 *  5. Animate to the new location using the stored viewstate.
 */
export function* fitMapToFeatures() {
  try {
    const { aggregatedOptions }: ProjectConfigLocationMapProps = yield select((state) => state.projectConfig);
    const { editorMapRef }: LocationMapInstanceState = yield select((state) => state.locationMapInstance);

    const map = editorMapRef?.mapRef?.getMap();
    if (!map) return;

    const featureCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };

    for (const marker of aggregatedOptions.markers) {
      if (marker.data.type === 'static') {
        // There is a typing issue between turf and the GeoJson typing library that we use
        // Should look into it, but not urgent
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        featureCollection.features.push(marker.data.content);
      }
    }

    if (featureCollection.features.length === 0) return;
    const oldViewState = {
      center: map.getCenter(),
      bearing: map.getBearing(),
      zoom: map.getZoom(),
      pitch: map.getPitch()
    };
    const featureBbox = bbox(featureCollection);
    const heightDifference = map.transform.height - aggregatedOptions.viewState.referenceHeight;
    const widthDifference = map.transform.width - aggregatedOptions.viewState.referenceWidth;
    map.fitBounds(
      // Same typing issue here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      featureBbox,
      {
        bearing: map.getBearing(),
        animate: false,
        maxZoom: featureCollection.features.length > 1 ? 24 : 11
      }
    );

    const elements = editorMapRef?.mapRef?.getElements();
    const padding: PaddingOptions = {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    };

    if (elements) {
      const canvasContainer = map.getCanvasContainer();
      const canvasRect = canvasContainer.getBoundingClientRect();

      elements.forEach((element) => {
        const targetRect = element.getBoundingClientRect();
        if (canvasRect.left - targetRect.left > padding.left) {
          padding.left = canvasRect.left - targetRect.left;
        }
        if (canvasRect.top - targetRect.top > padding.top) {
          padding.top = canvasRect.top - targetRect.top;
        }
        if (targetRect.right - canvasRect.right > padding.right) {
          padding.right = targetRect.right - canvasRect.right;
        }
        if (canvasRect.bottom - targetRect.bottom > padding.bottom) {
          padding.bottom = canvasRect.bottom - targetRect.bottom;
        }
      });
    }

    const defaultPadding = 24;
    padding.left += widthDifference / 2 + defaultPadding;
    padding.right += widthDifference / 2 + defaultPadding;
    padding.top += heightDifference / 2 + defaultPadding;
    padding.bottom += heightDifference / 2 + defaultPadding;

    map.fitBounds(
      // Same typing issue here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-expect-error
      featureBbox,
      {
        bearing: map.getBearing(),
        animate: false,
        padding: padding,
        maxZoom: featureCollection.features.length > 1 ? 24 : 11
      }
    );

    const newViewState = {
      center: map.getCenter(),
      bearing: map.getBearing(),
      zoom: map.getZoom(),
      pitch: map.getPitch()
    };
    map.jumpTo({
      ...oldViewState
    });

    map.flyTo({
      ...newViewState,
      animate: true,
      essential: true,
      duration: 1500
    });
  } catch (e) {
    console.log(e);
  }
}

export function* watchFitMapToFeatures() {
  yield takeEvery(actionTypes.locationMap.fitMapToFeatures, fitMapToFeatures);
}

export default function* rootSaga() {
  yield all([watchFitMapToFeatures()]);
}
