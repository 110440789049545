import { LocationMapContainerRef, LocationMapEntryPoint } from '@visual-elements/location-map';
import { inlineUpdateLocationMapMarkerAction, updateViewStateAction } from 'pages/ChartEditorPage/actions/locationMap';
import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectConfigLocationMapProps } from '../../../pages/Editor/reducers/locationMapConfigTypes';
import { updateAnimationProgress } from '../../../redux/reducers/locationMap/animationReducer';
import { storeLocationMapRef } from '../../../redux/reducers/locationMap/instanceReducer';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { ContextMenu } from '../../editor/generic/locationMap/ContextMenu';
import { ViewBox } from '../../editor/generic/locationMap/ViewBox';
import { LocationMapMappingOptions, mapToLocationMapProps } from '../utils/locationMapMapper';

const LocationMapPreview = () => {
  const { aggregatedOptions, urlParam, tab, provider, showWizard, locationMapOptions }: ProjectConfigLocationMapProps =
    useSelector(getProjectConfig);
  if (provider !== 'locationMap') throw new Error('Provider must be location map');

  const dispatch = useDispatch();

  const editorMapRef = useRef<LocationMapContainerRef | null>(null);
  const previewMapRef = useRef<LocationMapContainerRef | null>(null);
  const viewBoxRef = useRef<HTMLDivElement>(null);

  const isDataTab = showWizard ? urlParam === 'data' : tab === 'data';
  const isPublishTab = showWizard ? urlParam === 'publish' : tab === 'publish';
  const isTypeTab = showWizard ? urlParam === 'type' : tab === 'type';

  const handleOnPreviewMapRefSet = useCallback((locationMapContainerRef: LocationMapContainerRef | null) => {
    if (locationMapContainerRef) {
      previewMapRef.current = locationMapContainerRef;
      previewMapRef.current.on('animationProgress', (data) => {
        dispatch(updateAnimationProgress(data));
      });
      if (editorMapRef.current) {
        dispatch(storeLocationMapRef({ previewMapRef: previewMapRef.current, editorMapRef: editorMapRef.current }));
      }
    }
  }, []);

  const [mapContainer, setMapContainer] = useState<HTMLElement | undefined>();

  const handleOnEditorMapRefSet = useCallback((locationMapContainerRef: LocationMapContainerRef | null) => {
    if (locationMapContainerRef) {
      editorMapRef.current = locationMapContainerRef;
      editorMapRef.current.on('mapEngineDefined', (mapInstance) => {
        setMapContainer(mapInstance.getContainer());
      });

      editorMapRef.current.on('animationProgress', (data) => {
        dispatch(updateAnimationProgress(data));
      });

      if (previewMapRef.current) {
        dispatch(storeLocationMapRef({ previewMapRef: previewMapRef.current, editorMapRef: editorMapRef.current }));
      }

      editorMapRef.current.on('markerIconChange', (id, data) => {
        dispatch(inlineUpdateLocationMapMarkerAction({ payload: data, id: id, target: 'icon' }));
      });
      editorMapRef.current.on('markerLabelChange', (id, data) => {
        dispatch(inlineUpdateLocationMapMarkerAction({ payload: data, id: id, target: 'label' }));
      });
      editorMapRef.current.on('viewStateChange', (data, origin) => {
        if (origin !== 'moveEnd') return;
        const viewBoxSize = viewBoxRef.current?.getBoundingClientRect();
        dispatch(
          updateViewStateAction({
            viewState: data,
            resolution: { height: viewBoxSize?.height ?? 0, width: viewBoxSize?.width ?? 0 }
          })
        );
      });
    }
  }, []);

  const { viewBoxAspectRatio, showViewBox } = { ...locationMapOptions.viewStateOptions };

  const previewMapMappingOptions: LocationMapMappingOptions = {
    disableText: false,
    editorMode: {
      disableLoading: true
    },
    reuseMaps: false,
    disableControls: false,
    resolution: { type: 'fill' },
    interactive: { override: true, value: false },
    viewState: { override: false },
    disableAttribution: false
  };
  const previewMapProps = mapToLocationMapProps(aggregatedOptions, previewMapMappingOptions);

  const editorMappingOptions: LocationMapMappingOptions = {
    disableText: isDataTab,
    editorMode: {
      enableFeatureEditing: !isPublishTab,
      disableAnimations: !isPublishTab,
      enableViewstateReporting: true,
      disableLayerRemoving: true,
      disableLoading: true
    },
    reuseMaps: showWizard,
    disableControls: false,
    resolution: { type: 'fill' },
    interactive: { override: true, value: true },
    disableAttribution: true,
    viewState: locationMapOptions.viewStateOptions.editorViewState
      ? { override: true, value: locationMapOptions.viewStateOptions.editorViewState }
      : { override: false }
  };
  const editorMapProps = mapToLocationMapProps(aggregatedOptions, editorMappingOptions);

  const showPreviewMap = isPublishTab;

  return (
    <div className="flex flex-col w-full h-full relative">
      <LocationMapEntryPoint
        {...previewMapProps}
        containerStyle={{
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: showPreviewMap ? 1 : 0
        }}
        ref={handleOnPreviewMapRefSet}
      />
      {!isTypeTab && !isPublishTab && <ContextMenu locationMapRef={editorMapRef.current} />}
      <LocationMapEntryPoint
        {...editorMapProps}
        containerStyle={{
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: showPreviewMap ? 0 : 1,
          zIndex: showPreviewMap ? -1 : 0
        }}
        ref={handleOnEditorMapRefSet}
      />

      {showViewBox &&
        mapContainer &&
        createPortal(
          <ViewBox ref={viewBoxRef} locationMapRef={editorMapRef.current} aspectRatio={viewBoxAspectRatio} />,
          mapContainer
        )}
    </div>
  );
};

export default LocationMapPreview;
