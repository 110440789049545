import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { components, ControlProps, OptionProps, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import MapApi, { LocatorResult } from '../../../editor/core/highed.mapapi';
import { setSearchResult } from '../../../redux/reducers/locationMap/searchReducer';
import LoaderIcon from '../../loader/LoaderIcon';
import { searchInputStyles } from '../../utils/selectStylesHelper';

type LocatorResultSelect = { label: string; value: LocatorResult };

const loadOptions = (query: string, callback: (option: LocatorResultSelect[]) => void) => {
  MapApi().getLatLong(query, (latlongResults: LocatorResult[] | false) => {
    if (latlongResults) callback(latlongResults.map((result) => ({ label: result.name, value: result })));
    else callback([]);
  });
};

const Control = ({ children, ...props }: ControlProps<LocatorResultSelect>) => (
  <components.Control {...props} className="highed-field-input">
    <i className="fa-solid fa-magnifying-glass pr-1" /> {children}
  </components.Control>
);

const LoadingIndicator = () => <LoaderIcon loading={true} iconClass="text-xl" />;

const debouncedLoadOptions = debounce(loadOptions, 650, { leading: false, trailing: true });

const Option = (props: OptionProps<LocatorResultSelect>) => (
  <components.Option {...props}>
    <div className={`text-base font-normal text ${props.isSelected ? 'text-ev-dark-purple' : 'text-ev-navy-blue-2'}`}>
      {props.data.label}
    </div>
  </components.Option>
);

export type LocationMapSearchProps = {
  className: string;
};
export const LocationMapSearch = (props: LocationMapSearchProps) => {
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState<LocatorResultSelect | undefined>();

  return (
    <div className={props.className}>
      <AsyncSelect
        isMulti={false}
        loadOptions={debouncedLoadOptions}
        cacheOptions={true}
        noOptionsMessage={(input) =>
          input.inputValue === '' ? (
            <div>Search for a place (e.g., city, landmark)...</div>
          ) : (
            <div>We could not find that location. Please check the spelling or try another place.</div>
          )
        }
        value={selectedValue}
        styles={searchInputStyles}
        controlShouldRenderValue={true}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        onChange={(ev: SingleValue<LocatorResultSelect>) => {
          if (!ev) return;
          setSelectedValue(ev);
          dispatch(setSearchResult({ result: ev.value }));
        }}
        placeholder="Search for a location to add a marker..."
        components={{ LoadingIndicator, Option, Control }}
      />
    </div>
  );
};
