import { isObject } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openPaidPlanModalAction } from '../../redux/actions/app';

type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  id?: string;
  buttonClass?: string;
  icon?: string | React.FunctionComponent<React.SVGAttributes<SVGElement>> | React.ReactNode;
  buttonText?: string | React.ReactNode;
  extraClass?: string;
  disabled?: boolean;
  type?: 'reset' | 'button' | 'submit' | undefined;
  iconLeft?: boolean;
  hasPermission?: boolean;
  iconType?: string;
  iconClass?: string;
  tabIndex?: number;
};

const Button = ({
  buttonClass,
  icon,
  buttonText,
  onClick,
  extraClass,
  disabled,
  type,
  iconLeft,
  hasPermission,
  id,
  iconType,
  iconClass,
  tabIndex
}: ButtonProps) => {
  const dispatch = useDispatch();
  const btnClass = buttonClass ? `bulk-action-btn ${buttonClass}` : extraClass;
  const iconPrefix = iconType || 'fa';
  const isCustomIcon = isObject(icon);

  const onInternalClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hasPermission !== undefined && !hasPermission) {
      dispatch(
        openPaidPlanModalAction({
          paidPlanType: 'starter'
        })
      );
      return;
    }
    onClick(e);
  };

  return (
    <button
      className={btnClass}
      onClick={onInternalClick}
      type={type || 'button'}
      id={id}
      tabIndex={tabIndex}
      disabled={disabled}
    >
      <>
        {isCustomIcon && icon}
        {!isCustomIcon && icon && iconLeft && <i className={`iconLeft ${iconPrefix} fa-${icon} ${iconClass}`} />}
        <span>
          {' '}
          {hasPermission !== undefined && !hasPermission && (
            <span className="has-no-permission">
              <i className="fa fa-lock" />
            </span>
          )}
          {buttonText}
        </span>
        {!isCustomIcon && icon && !iconLeft && <i className={`${iconPrefix} fa-${icon}  ${iconClass}`} />}
      </>
    </button>
  );
};

export default Button;
