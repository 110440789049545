import React from 'react';

import { LocationMapNavigationControls } from './LocationMapNavigationControls';
export default function LocationMapBottomBar() {
  return (
    <div className="w-full h-full flex justify-center">
      <div
        className="relative flex content-center gap-x-3 items-center pl-8 pr-8 border-b-r bg-white "
        style={{ borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}
      >
        <div className="absolute w-8 h-8 top-0 -left-8 bg-white" />
        <div className="absolute w-8 h-8 top-0 -left-8 bg-ev-grey" style={{ borderTopRightRadius: '30px' }} />
        <LocationMapNavigationControls tooltipPlacement="top" />
        <div className="absolute w-8 h-8 top-0 -right-8 bg-white" />
        <div className="absolute w-8 h-8 top-0 -right-8 bg-ev-grey" style={{ borderTopLeftRadius: '30px' }} />
      </div>
    </div>
  );
}
