import { nanoid } from '@reduxjs/toolkit';
import { LocationMapContainerRef } from '@visual-elements/location-map';
import { MapMouseEvent } from '@visual-elements/maplibre-gl';
import { ProjectConfigLocationMapProps } from 'pages/Editor/reducers/locationMapConfigTypes';
import React, { ReactNode, useEffect, useRef, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectViewIsLocked } from 'redux/reducers/locationMap/viewStateReducer';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { useOnClickOutside } from 'usehooks-ts';
import {
  addLocationMapMarkerAction,
  removeLocationMapMarkerAction
} from '../../../../pages/ChartEditorPage/actions/locationMap';

export const ContextMenu = (props: { locationMapRef: LocationMapContainerRef | null }) => {
  const dispatch = useDispatch();
  const { aggregatedOptions }: ProjectConfigLocationMapProps = useSelector(getProjectConfig);

  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [, startTransition] = useTransition();
  const [pointResult, setPointResult] = useState<
    { id?: string | number; label?: string; coordinates: [number, number] } | undefined
  >();
  const [selectedFeature, setSelectedFeature] = useState<{ id: string; type: 'marker' | 'area' } | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);

  const viewIsLocked = useSelector(selectViewIsLocked);
  useOnClickOutside(containerRef, () => setShowContextMenu(false));
  useEffect(() => {
    const map = props.locationMapRef?.mapRef?.getMap();
    const handleOnContextMenu = (e: MapMouseEvent & object) => {
      const selectedFeature = props.locationMapRef?.mapRef?.getSelectedFeature();
      setSelectedFeature(selectedFeature);
      setShowContextMenu(true);
      setContextMenuPosition([e.point.x, e.point.y]);
      setPointResult({ coordinates: [e.lngLat.lng, e.lngLat.lat] });
      startTransition(() => {
        const renderedFeatures = e.target.queryRenderedFeatures(e.point, {});
        let pointResult: { id?: string | number; label?: string; coordinates: [number, number] } | null = null;
        for (const renderedFeature of renderedFeatures) {
          if (pointResult === null && renderedFeature.layer.type === 'symbol') {
            const textField = renderedFeature.layer.layout?.['text-field'];
            if (textField && renderedFeature.geometry.type === 'Point') {
              const formatString = textField.toString().replace('{', '').replace('}', '');
              pointResult = {
                id: renderedFeature.id,
                label: renderedFeature.properties[formatString],
                coordinates: [renderedFeature.geometry.coordinates[0], renderedFeature.geometry.coordinates[1]]
              };
            }
          }
        }
        if (pointResult) {
          setPointResult(pointResult);
        }
      });
    };
    if (map) {
      map.on('contextmenu', handleOnContextMenu);
    }
    return () => {
      map?.off('contextmenu', handleOnContextMenu);
    };
  }, [props.locationMapRef]);
  if (!showContextMenu) return <></>;

  const marker = selectedFeature ? aggregatedOptions.markers.find((x) => x.id === selectedFeature.id) : undefined;
  return (
    <div
      ref={containerRef}
      className="absolute z-10 shadow-lg rounded flex flex-col border-ev-navy-blue bg-white p-2 gap-1 divide-y divide-ev-grey min-w-[215px]"
      tabIndex={0}
      style={{
        left: contextMenuPosition[0] + 5,
        top: contextMenuPosition[1] + 5
      }}
    >
      {pointResult && !selectedFeature && (
        <ContextMenuItem
          onClick={() => {
            dispatch(
              addLocationMapMarkerAction({
                id: nanoid(8),
                label: pointResult.label,
                location: pointResult.coordinates,
                type: 'PinMarker'
              })
            );
            setShowContextMenu(false);
          }}
        >
          <ContextMenuIcon icon="location-dot" />
          <ContextMenuText>Add marker</ContextMenuText>
        </ContextMenuItem>
      )}
      {selectedFeature && (
        <>
          {!viewIsLocked && (
            <ContextMenuItem
              onClick={() => {
                if (
                  marker?.data.type === 'static' &&
                  marker.data.content.type === 'Feature' &&
                  marker.data.content.geometry.type === 'Point'
                ) {
                  props.locationMapRef?.mapRef?.getMap()?.flyTo({
                    center: [marker.data.content.geometry.coordinates[0], marker.data.content.geometry.coordinates[1]]
                  });
                }
                setShowContextMenu(false);
              }}
            >
              <ContextMenuIcon icon="magnifying-glass-plus" />
              <ContextMenuText>Zoom to marker</ContextMenuText>
            </ContextMenuItem>
          )}
          <ContextMenuItem
            onClick={() => {
              dispatch(removeLocationMapMarkerAction({ id: selectedFeature.id }));
              setShowContextMenu(false);
            }}
          >
            <ContextMenuIcon icon="trash-can " />
            <ContextMenuText>Delete marker</ContextMenuText>
          </ContextMenuItem>
        </>
      )}
    </div>
  );
};

export const ContextMenuItem = (props: { children: ReactNode; onClick: () => void }) => {
  return (
    <div
      className="pl-2 pr-2 flex items-center cursor-pointer hover:bg-ev-baby-blue-2 bg-ev-grey h-8"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export const ContextMenuIcon = (props: { icon: string }) => {
  return <i className={`iconLeft fa fa-solid fa-${props.icon} pr-2`} />;
};
export const ContextMenuText = (props: { children: string }) => {
  return <span className="font-bold">{props.children}</span>;
};
