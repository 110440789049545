import { resetStateAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import { resetStateAction as resetChartState } from 'pages/ChartEditorPage/actions/chartEditor';
import { resetProjectConfigAction } from 'redux/actions/projectConfig';
import { revertLocationMapState } from 'redux/actions/locationMap';
import { resetThemePageSettingsAction } from 'pages/CompanyThemeEditorPage/actions/ThemeEditorPage';
import {
  getEditorDivisionWithDivisionid,
  getEditorTeamWithTeamid,
  getTeamFontsWithTeamid
} from '../../api/cloudApiGenerated';

export const getEditorConfig = (team, division, anonymous) =>
  new Promise((resolve) => {
    if (division && division.id !== null) {
      return getEditorDivisionWithDivisionid(division.id).then((data) => {
        return resolve(data);
      });
    } else if (!anonymous) {
      return getEditorTeamWithTeamid(team.id).then((data) => {
        return resolve(data);
      });
    }
    return resolve();
  });

export const getCustomFonts = (team, anonymous) =>
  new Promise((resolve) => {
    if (!anonymous) {
      return getTeamFontsWithTeamid(team.id).then((data) => {
        if (data) {
          data.forEach((font) => {
            font.title = font.name;
            font.id = window.hcconfig.frontend.hostname + '/fonts/' + font.uuid + '/font.css';
          });
        }
        return resolve(data);
      });
    }
    return resolve();
  });

export const generateEditorSettings = async (team, division, anonymous, isThemeEditor, useEditorConfig) => {
  const customFonts = await getCustomFonts(team, anonymous);
  let data = false;
  if (useEditorConfig) data = await getEditorConfig(team, division, anonymous);

  let editorConfig = {
    features: 'data templates themes customize publish export help-cloud',
    useContextMenu: false,
    useHeader: false
  };

  if (data?.length > 0) {
    editorConfig = JSON.parse(data[0].details);
    editorConfig.features = ' data ' + editorConfig.features + ' help-cloud';
    editorConfig.useContextMenu = false;
    editorConfig.useHeader = false;

    let config = editorConfig.customizer?.availableSettings;
    if (config) {
      config = config.map((d) => d.replaceAll('--', '.').replaceAll('-', '.'));
      editorConfig.customizer.availableSettings = config;
    }
  }

  if (customFonts) editorConfig.customFonts = customFonts;

  if (isThemeEditor) editorConfig.features = editorConfig.features.replace(' themes', '');
  return { editorConfig, data };
};

export const generateQueryString = (qs, tab, type, showWizard, isMap, template, section) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const toQueryParamString = (params, ignore) =>
    Object.keys(params)
      .map((key) => {
        if (!ignore.includes(key)) return key + (params[key] ? '=' + params[key] : '');
      })
      .filter((d) => d)
      .join('&');

  let queryString = toQueryParamString(query, ['panel', 'editor', 'type', 'section']);
  if (queryString !== '') queryString = '&' + queryString;

  if (type === 'chart' && isMap) tab = `map_${tab}`;
  else tab = `${type}_${tab}`;

  const wiz = `editor${showWizard ? '=wiz' : ''}`;
  const tempTemplate = template ? `&type=${template}` : '';
  const tempSection = section ? `&section=${section}` : '';

  return `?${wiz}&panel=${tab}${tempTemplate}${tempSection}${queryString}`;
};

export const resetReduxState = (dispatch) => {
  dispatch(resetStateAction({}));
  dispatch(resetChartState());
  dispatch(resetProjectConfigAction({}));
  dispatch(resetThemePageSettingsAction());
  dispatch(revertLocationMapState());
};
